import { View } from "../../views/View";
import { DropdownView } from "./DropdownView";

export class AddpostView extends View {
    protected readonly dropdownViews: Array<View> = [];

    constructor(el: HTMLElement) {
        super('addpost', el);
    }
    public init(): void {
        super.init();

        // [activate DD]
        const postElList = this.el.querySelectorAll<HTMLElement>('select[data-view="dropdown"]');
        for (let postEl of postElList) {
            this.dropdownViews.push(new DropdownView(postEl));
        }
        this.dropdownViews.forEach(v => v.init());
    }
    public dispose(): void {
        super.dispose();
        this.dropdownViews.forEach(v => v.dispose());
        this.dropdownViews.length = 0;
    }
}



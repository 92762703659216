import { FormValidationInteractor } from '../../interactors/FormValidationInteractor';
import { MvpView } from '../../mvp/MvpView';
import { AlertsView } from '../../views/AlertsView';
import { FormModel } from '../model/FormModel';
import { FormPresenter } from '../presenter/FormPresenter';

export class FormView extends MvpView<FormPresenter> {
    protected readonly submitEventListener = (ev: SubmitEvent) => this.handleSubmit.call(this, ev);
    public alertsView?: AlertsView;
    public validate?: boolean = false;

    constructor(el: HTMLFormElement,
        protected readonly formValidationInteractor?: FormValidationInteractor) {
        super('form', el);
    }
    protected createPresenter(): FormPresenter {
        if (this.el instanceof HTMLFormElement) {
            const model = new FormModel();
            model.handlerUrl = this.el.getAttribute('action')!;
            model.validate = !!this.validate;
            return new FormPresenter(this.formValidationInteractor, model);
        } else {
            throw new TypeError('form view: given element is not <form> tag');
        }
    }
    public init(): void {
        if (this.el.classList.contains('validate') ||
            this.el.getAttribute('data-validate') === 'perform') {
            this.validate = true;
        }

        // try to find AlertsView
        let alertsEl;
        if ((alertsEl = this.el.querySelector<HTMLElement>('*[data-view="alerts"]'))) {
            this.alertsView = new AlertsView(alertsEl);
        }

        //
        super.init();
        this.alertsView?.init();
        //
        this.el.addEventListener('submit', this.submitEventListener);
    }
    public dispose(): void {
        super.dispose();
        this.el.removeEventListener('submit', this.submitEventListener);
        this.alertsView?.dispose();
    }
    public readFormData() {
        this.presenter?.updateFormData(new FormData(this.el as HTMLFormElement));
    }
    public doSubmit(): void {
        (this.el as HTMLFormElement).requestSubmit();
    }
    public showValidationError(errorMessage: string): void {

        if (this.alertsView) {
            this.alertsView.setAlerts([
                { class: "error", messageText: errorMessage }
            ]);
        }
        errorMessage?.length && alert(errorMessage);
    }
    public showError(e: any): void {
        alert(`Unable to validate form: ${e?.message}`);
    }
    public clearErrorAlerts() {
        this.alertsView?.setAlerts([]);
    }

    protected handleSubmit(ev: SubmitEvent) {

        // TODO ???
        if (this.presenter?.model?.validate) {
            this.presenter?.validate();
        }
        if (!this.presenter?.model?.allowSubmit) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }
}

import { View } from "./View";

export class FragmentView extends View {
    declare public readonly name: string;
    protected layout: string | undefined;
    protected key: string | undefined;
    protected readonly childList: Array<FragmentView>;

    private constructor(el: HTMLElement) {
        super(el.dataset.name!, el, true);
        this.layout = el.dataset.layout ?? undefined;
        this.key = el.dataset.key ?? undefined;
        this.childList = [];
    }
    public static create(el: HTMLElement): FragmentView {
        const fragment = new FragmentView(el);
        fragment.instantiateChildFragments(el);
        return fragment;
    }

    public init(): void {
        if (!this.isInit) {
            // console.log('frag', this.name, ' init');
            this.childList.forEach(v => v.init());
        }
        super.init();

    }
    public dispose(): void {
        if (!this._isDisposed) {
            // console.log('frag', this.name, ' disposed');
            this.childList.forEach(v => v.dispose());
            this.childList.length = 0;
        }
        super.dispose();
    }

    private instantiateChildFragments(el?: Node) {
        let node, fragment;
        node = (el ?? this.el).firstChild;
        while (node) {
            if ((node instanceof HTMLElement) &&
                node.classList.contains('fragment')) {
                // если указан фрагмент, то ниже будет 
                // спускаться уже инстанс этого фрагмента (FragmentView)...
                fragment = new FragmentView(node);
                // console.log('found new fragment: ', fragment.name);
                this.childList.push(fragment);
                fragment.instantiateChildFragments(node);
            } else
                this.instantiateChildFragments(node);

            node = node.nextSibling;
        }
    }
    private getByName(name: string): FragmentView | undefined {
        for (let child of this.childList) {
            if (name === child.name)
                return child;
        }
        return undefined;
    }

    public static replaceContent(currFragment: FragmentView, newFragment: FragmentView): void {
        let child, oldFragment;

        if (currFragment.name !== newFragment.name)
            throw new Error('illegal fragment provided');

        if (String(currFragment.layout) === String(newFragment.layout) &&
            currFragment.childList.length > 0) {
            // console.log('replacing sub-fragments of: ', currFragment.name);
            // указанная разметка совпадает, необходимо заменить каждый под-фрагмент отдельно
            for (child of newFragment.childList) {
                oldFragment = currFragment.getByName(child.name);
                if (oldFragment)
                    FragmentView.replaceContent(oldFragment, child);
                // console.log('child fragment not found: ', child.name);
            }
        } else {

            // если ключ фрагмента совпадает, не нужно выполнять замену вообще
            if (((currFragment.key !== undefined && newFragment.key !== undefined) &&
                String(currFragment.key) === String(newFragment.key)))
                return;

            // console.log('replacing fragment fully: ', currFragment.name);
            // в данном случае, заменяем контент всего фрагмента...
            currFragment.dispose();
            currFragment.el.replaceChildren(...newFragment.el.children);
            currFragment.instantiateChildFragments(currFragment.el);
            currFragment._isDisposed = false;
            currFragment.init();
            //
            currFragment.key = newFragment.key;
            currFragment.layout = newFragment.layout;
            //
            if (newFragment.key)
                currFragment.el.setAttribute('data-key', newFragment.key);
            else
                currFragment.el.removeAttribute('data-key');
            //
            if (newFragment.layout)
                currFragment.el.setAttribute('data-layout', newFragment.layout);
            else
                currFragment.el.removeAttribute('data-layout');
        }
    }
}

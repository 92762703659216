import { View } from "../../../views/View";
import { SiteHeaderView } from "../SiteHeaderView";

export class SiteDefaultLayoutView extends View {
    public headerView?: SiteHeaderView;

    constructor(el: HTMLElement,
        public readonly contentView?: View) {
        super('siteDefaultLayout', el, true);
        //
    }
    public init(): void {
        super.init();
        let headerEl: HTMLElement | null,
            footerEl: HTMLElement | null;

        if ((headerEl = this.el.querySelector('header[data-view="siteHeader"]')))
            this.headerView = new SiteHeaderView(headerEl);

        footerEl = this.el.querySelector('footer[data-view="siteFooter"]');
        footerEl && null; // TODO use footer
        //
        this.headerView?.init();
        this.contentView?.init();
    }
    public dispose(): void {
        this.headerView?.dispose();
        this.contentView?.dispose();
        super.dispose();
    }
}

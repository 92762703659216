import { CaptchaView } from "../../../forms/view/CaptchaView";
import { FormView } from "../../../forms/view/FormView";
import { CaptchaInteractor } from "../../../interactors/CaptchaInteractor";
import { FormValidationInteractor } from "../../../interactors/FormValidationInteractor";
import { View } from "../../../views/View";

export class UserRegisterView extends View {
    protected formView?: FormView;
    protected captchaView?: CaptchaView;
    constructor(el: HTMLElement,
        protected readonly formValidationInteractor?: FormValidationInteractor,
        protected readonly captchaInteractor?: CaptchaInteractor) {
        super('userRegister', el);
    }
    public init(): void {
        super.init();
        console.log('userRegister');

        // [REGISTER FORM]
        const formEl = this.el.querySelector<HTMLFormElement>('form.reg-form');
        if (formEl && this.formValidationInteractor) {
            this.formView = new FormView(formEl, this.formValidationInteractor);
            //
            const captchaEl = formEl.querySelector<HTMLFormElement>('.captcha');
            if (captchaEl && this.captchaInteractor)
                this.captchaView = new CaptchaView(captchaEl, this.captchaInteractor);
        }
        //
        this.formView?.init();
        this.captchaView?.init();
    }
    public dispose(): void {
        super.dispose();
        this.formView?.dispose();
        this.captchaView?.dispose();
    }
}

import { LiveInteractor } from '../../interactors/LiveInteractor';
import { Component } from '../Component';
import { LiveApp } from '../../LiveApp';

export type NavParams = {
    url: URL;
    pushState: boolean;
    method: "GET" | "POST";
    data?: FormData | URLSearchParams;
};

const INTERVAL = 25;
const AVG_RESP_TIME = 500;

export class LiveNavigationComponent extends Component {
    protected abortCtl?: AbortController;
    protected isProcessing: boolean;
    protected isDone: boolean;
    protected isDataDownloaded: boolean;
    protected isFailed?: boolean;
    protected startTime?: number;
    protected checkpointParams?: NavParams;

    constructor(protected readonly liveInteractor: LiveInteractor,
        protected readonly liveApp: LiveApp) {
        super();
        this.isProcessing = false;
        this.isDone = true;
        this.isDataDownloaded = true;
    }

    public activate(): void {
    }

    public destroy(): void {
        delete this.startTime, this.checkpointParams, this.abortCtl;
    }

    public async navigateTo(params: NavParams): Promise<void> {

        if (this.isProcessing || !this.liveApp.isLiveNavAllowed)
            return;
        this.isProcessing = true;
        this.isDone = false;
        this.isDataDownloaded = false;
        this.isFailed = false;
        this.checkpointParams = params;
        this.liveApp.onBeginLiveLoading();

        const signal = (this.abortCtl = new AbortController()).signal;
        try {
            this.updateProgress();

            // TODO use progressCallback here?
            const liveData = await this.liveInteractor.fetchPage({
                url: params.url.href,
                method: params.method,
                body: ('data' in params ? params.data : undefined),
                signal
            });

            this.isDataDownloaded = true;
            this.updateProgress();

            if ('errorMessage' in liveData)
                throw new Error(liveData.errorMessage ?? '');

            await this.liveApp.handle(liveData, params);
            delete this.checkpointParams;
        } catch (e) {
            // console.log(e);
            if (!signal.aborted) {
                this.isFailed = true;
                this.liveApp.displayError(e);
            }
        } finally {
            delete this.abortCtl;
            this.isProcessing = false;
            this.isDone = true;
            this.updateProgress();
        }
    }

    public async retryFailedAttempt() {
        if (this.isFailed && this.checkpointParams) {
            await this.navigateTo(this.checkpointParams);
        }
    }

    protected updateProgress(): void {
        if (this.isDone) {
            // 100%
            if (this.startTime) {
                delete this.startTime;
                this.liveApp.updateProgress(100);
            } else
                this.liveApp.updateProgress(0);

        } else if (this.isDataDownloaded) {
            // 85..%
            this.liveApp.updateProgress(85);
        } else if (this.isProcessing) {
            // 20-85%

            this.startTime = this.startTime ?? performance.now();
            const diff = performance.now() - this.startTime,
                clamped = Math.min(diff, AVG_RESP_TIME),
                p = 20 + (65 * (clamped / AVG_RESP_TIME));

            this.liveApp.updateProgress(p);
            setTimeout(this.updateProgress.bind(this), INTERVAL);
        }
    }

    public cancelLoading(): void {
        if (this.isProcessing) {
            this.abortCtl?.abort();
            this.isProcessing = false;
            // this.loadingErrorView?.hide();
        }
    }
}

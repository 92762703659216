import { ViewManager } from './ViewManager';

export class View {
    protected isInit?: boolean;
    protected _isDisposed?: boolean;
    public readonly id: number;

    public constructor(public readonly name: string,
        public readonly el: HTMLElement,
        protected readonly transient: boolean = false) {
        if (!this.transient) {
            if (View.vid(el)) {
                throw new Error('element is already bound to view, name=' + this.name);
            }
            this.id = ViewManager.instance.addInst(this);
            this.el.setAttribute('data-vid', String(this.id));
        } else {
            this.id = -1;
        }
    }
    public init(): void {
        if (this.isDisposed) {
            throw new Error('view is already disposed');
        } else if (this.isInit) {
            return;
        }
        this.isInit = true;
    }
    public get isDisposed(): boolean { return !!this._isDisposed; }
    public dispose(): void {
        if (this.isInit && !this._isDisposed) {
            this._isDisposed = true;
            this.isInit = false;
            this.el.removeAttribute('data-vid');
        }
    }
    //
    public static vid(el: HTMLElement): number | undefined {
        const str = el.getAttribute('data-vid');
        return str ? parseInt(str) : undefined;
    }
    public static ofEl(el: HTMLElement): View | null {
        const id = View.vid(el);
        return id ? ViewManager.instance?.getById(id).inst : null;
    }
}

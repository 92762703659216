import { View } from "./View";

export class ProgressBarView extends View {
    protected _progress: number = 0;

    constructor(el: HTMLElement) {
        super('progressBar', el);
    }

    public init(): void {
        super.init();
        this.progress = 0;
    }
    public get progress(): number {
        return this._progress ?? 0;
    }
    public set progress(n: number) {
        this._progress = n <= 0 || n > 100 ? 0 : n;
        if (this.el) {
            this.el.style.width = this._progress + '%';
        }
    }
}


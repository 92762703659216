import { LiveNavigationComponent } from "../components/internal/LiveNavigationComponent";
import { View } from "./View";

export class LiveLoadingErrorView extends View {
    protected msgEl?: HTMLElement;
    protected reloadBtnEl?: HTMLElement;
    protected readonly reloadClickEventListener = (ev: MouseEvent) => this.doReload.call(this, ev);
    protected readonly containerClickEventListener = () => this.hide.call(this);
    //
    protected defaultMessage?: string;

    constructor(el: HTMLElement,
        protected liveNavComponent?: LiveNavigationComponent) {
        super('liveLoadingError', el);
    }

    public init(): void {
        this.msgEl = this.el!.querySelector<HTMLElement>('*[rel=message]')!;
        this.reloadBtnEl = this.el!.querySelector<HTMLElement>('*[rel=reload]')!;
        this.defaultMessage = this.el.getAttribute('data-message') ?? '';
        //
        super.init();
        //
        this.reloadBtnEl?.addEventListener('click', this.reloadClickEventListener);
        this.el?.addEventListener('click', this.containerClickEventListener);
        this.hide();
    }
    public dispose(): void {
        super.dispose();
        this.reloadBtnEl?.removeEventListener('click', this.reloadClickEventListener);
        this.el?.removeEventListener('click', this.containerClickEventListener);
    }
    displayError(e: any): void {
        if (this.msgEl) {
            this.msgEl.textContent = e.message ?? this.defaultMessage ?? "";
        }
        this.el!.classList.remove('hide');
    }
    hide(): void {
        this.el!.classList.add('hide');
    }

    doReload(ev: MouseEvent): void {
        ev.preventDefault();

        if (this.liveNavComponent)
            this.liveNavComponent.retryFailedAttempt();
        else
            window.location.reload();
    }

}

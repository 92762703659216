import { View } from "../../views/View";

export class SiteHeaderView extends View {
    private triggerEl?: HTMLLinkElement;
    private nav?: HTMLDivElement;
    private readonly onTriggerClickedListener: (this: HTMLLinkElement, ev: MouseEvent) => any
        = this.handleTriggerClick.bind(this);
    private readonly onMenuLinkClickedListener: (this: HTMLDivElement, ev: MouseEvent) => any
        = this.handleMenuLinkClick.bind(this);
    //
    constructor(el: HTMLElement) {
        super('siteHeader', el);
    }
    public init(): void {
        super.init();

        // Menu Dropdown Toggle
        if ((this.triggerEl = this.el.querySelector('.menu-trigger')!)) {
            this.nav = this.el.querySelector('.header-area .nav')!;
            this.triggerEl.addEventListener('click', this.onTriggerClickedListener);
            this.nav?.addEventListener('click', this.onMenuLinkClickedListener);
        }
    }
    public dispose(): void {
        super.dispose();
        this.triggerEl?.removeEventListener('click', this.onTriggerClickedListener);
        this.nav?.removeEventListener('click', this.onMenuLinkClickedListener);
        //
        delete this.triggerEl, this.nav;
    }
    private handleTriggerClick(ev: Event) {
        ev.preventDefault();
        ev.stopPropagation();
        //

        if (this.triggerEl && this.nav) {
            this.triggerEl.classList.toggle('active');

            if (this.triggerEl.classList.contains('active')) {
                this.nav.style.display = 'block';
            } else {
                this.nav.style.display = 'none';
            }
        }
    }
    private handleMenuLinkClick() {
        if (this.triggerEl && this.nav) {
            this.triggerEl.classList.remove('active');
            this.nav.style.display = 'none';
        }
    }
}

import { GenericApiClient } from "./ApiClient";
import { Component } from "./components/Component";
import { AuthComponent } from "./components/internal/AuthComponent";
import { ApiInteractor } from "./interactors/ApiInteractor";
import { TokenInteractor } from "./interactors/TokenInteractor";
import { AppView } from "./views/AppView";
import { ViewFactory } from "./views/ViewFactory";

export class App extends Component {
    protected readonly appView: AppView;
    //
    public readonly viewFactory: ViewFactory;
    public readonly apiClient: GenericApiClient;
    public readonly authComponent: AuthComponent;
    public readonly apiClientAuth: GenericApiClient;

    constructor() {
        super();
        this.viewFactory = new ViewFactory();
        this.appView = new AppView(this.viewFactory, this);
        //
        this.apiClient = new GenericApiClient(new ApiInteractor());
        this.authComponent = new AuthComponent(new TokenInteractor(this.apiClient));
        this.apiClientAuth = new GenericApiClient(new ApiInteractor(this.authComponent));
    }
    public activate(): void {
        this.authComponent.activate();
        this.appView.init();
    }

    public destroy(): void {
        this.authComponent.destroy();
        this.appView.dispose();
    }

    public applyInitialState(state: object): void {
        for (let [k, v] of Object.entries(state)) {
            if (k === 'access_token') {
                this.authComponent.setAccessTokenFromJsonStr(v);
            } else if (k === 'refresh_token') {
                this.authComponent.refreshToken = String(v);
            } else if (k === 'api_url') {
                this.apiClient.apiUrl = String(v);
            }
        }
    }
}

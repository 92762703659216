import { FormView } from "../../forms/view/FormView";
import { FormValidationInteractor } from "../../interactors/FormValidationInteractor";
import { View } from "../../views/View";
import { PostLazyImageView } from "./PostLazyImageView";
import { QuickSearchFormView } from "./QuickSearchFormView";
import { TransparentPostLinkView } from "./TransparentPostLinkView";

export class SerpView extends View {
    protected quickSearchFormView?: QuickSearchFormView;
    protected readonly postViews: Array<View> = [];
    protected placeOrderFormView?: FormView;

    constructor(el: HTMLElement,
        protected readonly formValidationInteractor?: FormValidationInteractor) {
        super('serp', el);
    }
    public init(): void {
        super.init();

        // [QUICK SEARCH FORM]
        const quickSearchFormEl = this.el.querySelector<HTMLFormElement>('form[data-view="quickSearchForm"]');
        if (quickSearchFormEl) {
            this.quickSearchFormView = new QuickSearchFormView(quickSearchFormEl);
        }

        // [POSTS]
        const postElList = this.el.querySelectorAll<HTMLElement>('.listing-item');
        for (let postEl of postElList) {
            this.postViews.push(new TransparentPostLinkView(postEl));
            const featuredImageEl = postEl.querySelector<HTMLElement>('.featured-image');
            if (featuredImageEl)
                this.postViews.push(new PostLazyImageView(featuredImageEl));
        }
        
        // [SERP PLACE ORDER FORM]
        const placeOrderFormEl = this.el.querySelector<HTMLFormElement>('form#place-order-form');
        if (placeOrderFormEl) {
            this.placeOrderFormView = new FormView(placeOrderFormEl, this.formValidationInteractor);
        }

        //
        this.placeOrderFormView?.init();
        this.quickSearchFormView?.init();
        this.postViews.forEach(v => v.init());
    }
    public dispose(): void {
        super.dispose();
        this.placeOrderFormView?.dispose();
        this.quickSearchFormView?.dispose();
        this.postViews.forEach(v => v.dispose());
        this.postViews.length = 0;
    }
}


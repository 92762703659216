import { AuthComponent } from '../components/internal/AuthComponent';
import { FormModel } from '../forms/model/FormModel';
import { axiosInstance, responseBody } from '../helpers/HttpHelper';
import axios, { type AxiosRequestConfig } from 'axios';

type ValidationResponse = {
    "SUCCESS": boolean;
    "ERROR_MESSAGE": string;
};

export type ValidationResult = {
    ok: true;
} | {
    ok: false;
    errorMessage: string;
};


export class FormValidationInteractor {
    constructor(public authComponent: AuthComponent) {
    }

    public async validateForm(model: FormModel): Promise<ValidationResult> {

        const config: AxiosRequestConfig<FormData> = {
            url: model.handlerUrl,
            method: "POST",
            data: model.formData,
            withCredentials: false,
            headers: {
                "X-Form-Validate": "validate",
                "X-Requested-With": "XMLHttpRequest",
                ...(await this.authComponent.authHeaders())
            }
        };

        try {
            const responseData = await axiosInstance.request<ValidationResponse>(config)
                .then(responseBody);
            return {
                ok: !!responseData.SUCCESS,
                errorMessage: (responseData.ERROR_MESSAGE ?? '')
            };
        } catch (e: any) {
//            console.log(e);
            if (axios.isAxiosError(e)) {
                // TODO handle axios error!!!
            }
            throw e;
        }
    }
}

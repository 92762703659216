import { FormView } from "../../forms/view/FormView";
import { FormValidationInteractor } from "../../interactors/FormValidationInteractor";
import { View } from "../../views/View";

export class ShowpostView extends View {
    protected placeOrderFormView?: FormView;

    constructor(el: HTMLElement,
        protected readonly formValidationInteractor?: FormValidationInteractor) {
        super('showpost', el);
    }
    public init(): void {
        super.init();
        
        // [PLACE ORDER FORM]
        const placeOrderFormEl = this.el.querySelector<HTMLFormElement>('form#place-order-form');
        if (placeOrderFormEl) {
            this.placeOrderFormView = new FormView(placeOrderFormEl, this.formValidationInteractor);
        }

        //
        this.placeOrderFormView?.init();
    }
    public dispose(): void {
        super.dispose();
        this.placeOrderFormView?.dispose();
    }
}


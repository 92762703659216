import Choices from "choices.js";
import { View } from "../../views/View";

export class DropdownView extends View {
    protected choices: Choices | undefined;
    constructor(el: HTMLElement) {
        super('dropdown', el);
    }
    public init(): void {
        super.init();
        this.choices = new Choices(this.el, {
            silent: true,
            removeItemButton: true,
            allowHTML: false,
            duplicateItemsAllowed: false,
            shouldSort: false,
            noChoicesText: '',
            itemSelectText: ''
        });
    }
    public dispose(): void {
        super.dispose();
        this.choices?.destroy();
        delete this.choices;
    }
}

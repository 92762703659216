import { Component } from '../Component';
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { TokenInteractor } from '../../interactors/TokenInteractor';

const DEFAULT_ACCESS_TOKEN_TTL_SEC = 14400;

function isExpired(validUntil: Dayjs): boolean {
    return !!validUntil.isBefore(dayjs());
}

export class AuthComponent extends Component {
    protected accessToken?: string;
    protected validUntil?: Dayjs;
    public refreshToken?: string;
    public impersonateTargetUserId?: number;

    constructor(public tokenInteractor: TokenInteractor) {
        super();
    }
    public activate(): void {
    }
    public destroy(): void {
        delete this.accessToken, this.validUntil, this.refreshToken;
    }
    public setNewAccessToken(accessToken: string,
        expiresIn: number | undefined = DEFAULT_ACCESS_TOKEN_TTL_SEC) {
        this.accessToken = accessToken;
        this.validUntil = dayjs().add(expiresIn, 'second');
    }
    public async getAccessToken(): Promise<{ value: string; } | undefined> {
        const expired = !this.validUntil || isExpired(this.validUntil);
        if (expired && this.refreshToken) {
            const result = await this.tokenInteractor.refreshToken(this.refreshToken);
            this.setNewAccessToken(result.accessToken, result.expiresIn);
        } else if (expired) {
            return;
        }
        return { value: this.accessToken! }
    }
    public setAccessTokenFromJsonStr(jsonStr: string): void {
        if (jsonStr !== '') {
            const data = JSON.parse(jsonStr) as {
                "token": string;
                "expires_in": number;
            };
            if ('token' in data) {
                this.setNewAccessToken(data['token'], data['expires_in']);
            } else {
                throw new Error('no "token" key present in given object');
            }
        }
    }
    public async authHeaders() {
        let accessToken = await this.getAccessToken();
        if (!accessToken) {
            return {};
        }
        return {
            "Authorization": (accessToken?.value ? `Bearer ${accessToken?.value}` : undefined),
            "X-Impersonate-User-Id": this.impersonateTargetUserId
        };
    }
}

import { App } from "./App";
import { LiveNavigationComponent, type NavParams } from "./components/internal/LiveNavigationComponent";
import { LiveInteractor, type LiveDataOk } from "./interactors/LiveInteractor";
import { FragmentView } from "./views/FragmentView";
import { LiveLoadingErrorView } from "./views/LiveLoadingErrorView";
import { LiveView } from "./views/LiveView";
import { ProgressBarView } from "./views/ProgressBarView";

function getCanonicalURL(doc: Document): URL {
    const canonicalEl = doc.head.querySelector<HTMLLinkElement>('link[rel=canonical][href]')!;
    return new URL(canonicalEl.href, doc.location?.href);
}
function setCanonicalURL(doc: Document, url: URL): void {
    const canonicalEl = doc.head.querySelector<HTMLLinkElement>('link[rel=canonical][href]')!;
    if (canonicalEl)
        canonicalEl.href = url.toString();
}
function eqURL(u1: URL, u2: URL) {
    return (u1.pathname + u1.search) === (u2.pathname + u2.search);
}
const CANONICAL_URL = getCanonicalURL(document);

export class LiveApp extends App {
    protected readonly pageFragment: FragmentView;
    public readonly liveNavComponent: LiveNavigationComponent;
    protected readonly popState = this.handlePopStateEvent.bind(this);
    protected currentURL?: URL;
    protected progressBarView?: ProgressBarView;
    protected loadingErrorView?: LiveLoadingErrorView;
    protected liveView: LiveView;

    constructor() {
        super();
        this.pageFragment = FragmentView.create(document.body);

        this.liveNavComponent = new LiveNavigationComponent(
            new LiveInteractor(this.authComponent), this);
        this.liveView = new LiveView(document.body, this.liveNavComponent);

        //
        this.progressBarView = new ProgressBarView(document.body.querySelector('*[data-view="progressBar"]')!);
        this.loadingErrorView = new LiveLoadingErrorView(document.body.querySelector('*[data-view="liveLoadingError"]')!, this.liveNavComponent);
    }

    public activate(): void {
        super.activate();
        this.liveNavComponent.activate();
        //
        this.pageFragment.init();
        this.liveView.init();
        this.progressBarView?.init();
        this.loadingErrorView?.init();
        //
        window.addEventListener('popstate', this.popState);

        // заменяем состояние текущим адресом страницы
        this.historyStateUpdate(CANONICAL_URL, 'replace');
    }
    public destroy(): void {
        super.destroy();
        this.liveNavComponent.destroy();
        //
        this.pageFragment.dispose();
        this.liveView.dispose();
        this.progressBarView?.dispose();
        this.loadingErrorView?.dispose();
        //
        window.removeEventListener('popstate', this.popState);
    }

    public updateProgress(arg0: number) {
        if (this.progressBarView)
            this.progressBarView.progress = arg0;
    }
    public displayError(e: any) {
        this.loadingErrorView?.displayError(e);
    }
    public onBeginLiveLoading() {
        this.loadingErrorView?.hide();
    }

    public async handle(liveData: LiveDataOk, params: NavParams) {
        const parsedDocument = (new DOMParser()).parseFromString(liveData.content, "text/html");
        //
        const newFragment = FragmentView.create(parsedDocument.body);
        const realURL = getCanonicalURL(parsedDocument);
        const newViewName = parsedDocument.body.getAttribute('data-view')!;

        //
        document.title = String(parsedDocument.title);
        FragmentView.replaceContent(this.pageFragment, newFragment);
        await this.appView.switchPageViewByName(newViewName);
        //
        // console.log(realURL.toString());
        setCanonicalURL(document, realURL);
        if (params.pushState)
            this.historyStateUpdate(realURL, 'push');
        //
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    public get isLiveNavAllowed() {
        return true && !this.viewFactory.isLoading;
    }

    protected historyStateUpdate(url: URL, act: "push" | "replace"): void {
        const fn = window.history[act === 'push' ? 'pushState' : 'replaceState'];
        const state = url.pathname + url.search;

        if (!this.currentURL || !eqURL(this.currentURL!, url)) {
            this.currentURL = url;
            fn.call(window.history,
                state, '', url.href);
        }
    }

    protected handlePopStateEvent(ev: PopStateEvent): void {
        if (ev.state) {
            // this.currentURL = new URL(ev.state, CANONICAL_URL);
            // console.log(ev.state);
            this.liveNavComponent.cancelLoading();
            this.liveNavComponent.navigateTo({
                method: 'GET',
                url: new URL(ev.state, CANONICAL_URL),
                pushState: false
            });
        }
    }
}


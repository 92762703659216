import { LiveApp } from './app/LiveApp';
import { CaptchaInteractor } from './app/interactors/CaptchaInteractor';
import { FormValidationInteractor } from './app/interactors/FormValidationInteractor';
import { SiteDefaultLayoutView } from './app/site/views/layout/SiteDefaultLayoutView';
import { SerpView } from "./app/site/views/SerpView";
import { UserRegisterView } from './app/site/views/user/UserRegisterView';
import { UserRemindPassView } from './app/site/views/user/UserRemindPassView';
import { PlaceNewOrderView } from './app/site/views/PlaceNewOrderView';
import { AddpostView } from './app/site/views/AddpostView';
import { ShowpostView } from './app/site/views/ShowpostView';

/*async function statCmp() {
    const statBundle = await import('./app/components/internal/specific/StatComponent');
    return (statBundle).StatComponent;
}*/

const app = new LiveApp();
const formValidationInteractor = new FormValidationInteractor(app.authComponent);
const captchaInteractor = new CaptchaInteractor(app.apiClient);

//userChangePass
app.viewFactory.setFactoryFn('userChangePass', async (vEl) => {
    return new SiteDefaultLayoutView(vEl);
});
//userMyAccount
app.viewFactory.setFactoryFn('userMyAccount', async (vEl) => {
    return new SiteDefaultLayoutView(vEl);
});
//userRegister
app.viewFactory.setFactoryFn('userRegister', async (vEl) => {
    return new SiteDefaultLayoutView(vEl, new UserRegisterView(vEl, formValidationInteractor, captchaInteractor));
});
//userRemindPass
app.viewFactory.setFactoryFn('userRemindPass', async (vEl) => {
    return new SiteDefaultLayoutView(vEl, new UserRemindPassView(vEl, formValidationInteractor, captchaInteractor));
});
//userResendActivationLetter
app.viewFactory.setFactoryFn('userResendActivationLetter', async (vEl) => {
    return new SiteDefaultLayoutView(vEl);
});
//userSignIn
app.viewFactory.setFactoryFn('userSignIn', async (vEl) => {
    return new SiteDefaultLayoutView(vEl);
});
//
//addpost
app.viewFactory.setFactoryFn('addpost', async (vEl) => {
    return new SiteDefaultLayoutView(vEl, new AddpostView(vEl));
});
//neworder
app.viewFactory.setFactoryFn('neworder', async (vEl) => {
    return new SiteDefaultLayoutView(vEl, new PlaceNewOrderView(vEl));
});
//serp
app.viewFactory.setFactoryFn('serp', async (vEl) => {
    return new SiteDefaultLayoutView(vEl, new SerpView(vEl, formValidationInteractor));
});
//showpost
app.viewFactory.setFactoryFn('showpost', async (vEl) => {
    return new SiteDefaultLayoutView(vEl, new ShowpostView(vEl, formValidationInteractor));
});

//
app.activate();

/*const unloadFn = () => {
    window.removeEventListener('unload', unloadFn);
    app.destroy();
};
window.addEventListener('unload', unloadFn);*/

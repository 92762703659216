import { View } from './View';

type ViewInstListItem = {
    id: number;
    inst: View;
};
type ViewInstList = Array<ViewInstListItem>;

export class ViewManager {
    protected static _instance: ViewManager | undefined;
    protected instList: ViewInstList;

    protected constructor() {
        this.instList = [];
    }
    public static get instance() {
        return (ViewManager._instance = (ViewManager._instance ?? new ViewManager()));
    }
    public addInst(inst: View): number {
        const id = this.instList.length++;
        this.instList[id] = { id, inst };
        return id;
    }
    public removeInst(inst: View): void {
        if (!inst.isDisposed) {
            inst.dispose();
        }
        delete this.instList[inst.id];
    }
    public grepViews(predicate: (v: View) => boolean): Array<View> {
        return this.instList.map(itm => itm.inst).filter(predicate);
    }
    public getViewsByName(name: string) {
        return this.grepViews(v => v.name === name);
    }
    public getAllViews() {
        return this.instList.map(itm => itm.inst);
    }
    public getById(id: number) {
        return this.instList[id];
    }
}

import { AxiosRequestConfig } from "axios";
import { axiosInstance, responseBody } from "../helpers/HttpHelper";
import { AuthComponent } from "../components/internal/AuthComponent";

export type LiveRequestFnOptions = {
    url: string;
    method: "GET" | "POST";
    body?: FormData | URLSearchParams;
    signal?: AbortSignal;
    progressCallback?: (percentCompleted: number) => void;
};

type LiveResponse = {
    "CONTENT": string;
} | {
    "ERROR_MESSAGE": string;
};
export type LiveDataOk = {
    title: string;
    content: string;
    canonicalUrl: string;
};
export type LiveDataFail = {
    errorMessage: string;
};
export type LiveData = LiveDataOk | LiveDataFail;

export class LiveInteractor {
    constructor(protected readonly authComponent: AuthComponent) {
    }

    async fetchPage(options: LiveRequestFnOptions): Promise<LiveData> {

        const config: AxiosRequestConfig<XMLHttpRequestBodyInit> = {
            url: options.url,
            withCredentials: false,
            headers: {
                "X-Live": "live",
                "X-Requested-With": "XMLHttpRequest",
                ...(await this.authComponent.authHeaders())
            },
            signal: options.signal
        };

        if (options.method === 'POST') {
            config.method = 'POST';
            config.data = options.body; // post FormData
        } else if (options.method === 'GET') {
            let searchParams = new URLSearchParams();

            if (options.body instanceof URLSearchParams) {
                searchParams = new URLSearchParams(options.body);
            } else if (options.body instanceof FormData) { // "GET" form submitted
                searchParams = new URLSearchParams(Array.from(
                    options.body.entries(),
                    ([key, value]) => [key, typeof value === 'string' ? value : value.name]));
            }
            searchParams.set("_t", String(new Date().getTime()));
            config.params = searchParams;
            config.method = 'GET';
            // console.log(config.params);
        } else {
            throw new Error('invalid method: ' + options.method);
        }

        const responseData = await axiosInstance.request<LiveResponse>(config)
            .then(responseBody);
        if (!responseData || !('CONTENT' in responseData || 'ERROR_MESSAGE' in responseData)) {
            throw new Error('invalid server response for the Live request');
        }

        if ('CONTENT' in responseData) {
            return {
                title: responseData['TITLE'],
                content: responseData['CONTENT'],
                canonicalUrl: responseData['CANONICAL_URL']
            };
        } else {
            return {
                errorMessage: responseData['ERROR_MESSAGE']
            };
        }
    }
}

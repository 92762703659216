import { View } from "./View";
import { ViewFactory } from "./ViewFactory";
import { App } from "../App";

export class AppView extends View {
    protected pageView?: View;
    constructor(protected readonly viewFactory: ViewFactory,
        protected readonly app: App) {
        super('app', document.body, true);
    }

    public init(): void {
        super.init();
        const newViewName = this.el.getAttribute('data-view')!;

        if (newViewName)
            this.switchPageViewByName(newViewName);
        //
        const metaTags = document.head.getElementsByTagName('meta');
        const initialMetaState = {};
        for (const metaTag of metaTags) {
            let name = metaTag.getAttribute('name'),
                content = metaTag.getAttribute('content');
            initialMetaState[name] = String(content);
        }
        this.app.applyInitialState(initialMetaState);
    }
    public dispose(): void {
        super.dispose();
        if (this.pageView) {
            this.pageView.dispose();
            this.el.removeAttribute('data-view');
            delete this.pageView;
        }
    }
    private disposeView(): void {

        if (this.pageView) {
            this.pageView.dispose();
            this.el.removeAttribute('data-view');
            delete this.pageView;
        }
    }
    private async setView(viewName: string | undefined): Promise<void> {
        let v;
        if (viewName) {
            this.el.setAttribute('data-view', viewName);
            v = await this.viewFactory.build(viewName, this.el);
        }
        if (v) {
            this.pageView = v;
            this.pageView.init();
        }
    }
    public async switchPageViewByName(viewName: string | undefined): Promise<void> {
        console.log('switch view', viewName);
        this.disposeView();
        await this.setView(viewName);
    }
}

import axios, { AxiosRequestConfig } from "axios";
import { ApiError, ApiMethod } from "../enums/ApiEnums";
import { AuthComponent } from "../components/internal/AuthComponent";
import { axiosInstance, responseBody } from "../helpers/HttpHelper";
import { ApiInvocationError } from "../ApiClient";

type ServerErrorData = {
    "ERROR_CODE": number;
    "MESSAGE": string | undefined | null;
} | {
    "UNEXPECTED_EXCEPTION": {
        "CLASS": string;
        "MESSAGE": string | undefined | null;
    }
};

function isServerError(result: any): result is ServerErrorData {
    return 'ERROR_CODE' in result || 'UNEXPECTED_EXCEPTION' in result;
}

function makeEx(data: ServerErrorData): ApiInvocationError {

    if ('UNEXPECTED_EXCEPTION' in data) {
        return new ApiInvocationError(true,
            ApiError.ERR_UNEXPECTED_EXCEPTION,
            String(data['UNEXPECTED_EXCEPTION']['MESSAGE']));
    } else {
        return new ApiInvocationError(false,
            data['ERROR_CODE'],
            String(data['MESSAGE']));
    }
}

type ApiRequestFnOptions = {
    apiUrl: string;
    apiMethod: ApiMethod;
    postData?: FormData;
};

export class ApiInteractor {
    constructor(protected readonly authComponent?: AuthComponent) {
    }

    public async invoke<T>(
        { apiUrl, apiMethod, postData }: ApiRequestFnOptions): Promise<T> {

        const config: AxiosRequestConfig<FormData> = {
            url: `${apiUrl}?METHOD=${ApiMethod[apiMethod]}`,
            method: "POST",
            data: postData,
            withCredentials: false,
            headers: {
                "X-Api-Method": ApiMethod[apiMethod],
                "X-Requested-With": "XMLHttpRequest",
                ...(await this.authComponent?.authHeaders() ?? {}),
            }
        };

        try {
            return await axiosInstance.request<T>(config)
                .then(responseBody);
        } catch (e: any) {
//            console.log(e.response?.data);
            let exData;
            if (axios.isAxiosError(e) && (exData = e.response?.data) &&
                isServerError(exData)) {
                throw makeEx(exData);
            }
            throw e;
        }
    }
}


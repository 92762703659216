import { Presenter } from './Presenter';
import { View } from '../views/View';

export abstract class MvpView<P extends Presenter<MvpView<P>>> extends View {
    protected constructor(public readonly name: string,
        public readonly el: HTMLElement,
        protected presenter?: P) {
        super(name, el);
    }

    protected createPresenter(): P | undefined {
        return undefined;
    }
    public init(): void {
        if (this.isInit) {
            return;
        }
        super.init();
        this.presenter = this.presenter ?? this.createPresenter();
        this.presenter?.onReady(this);
    }
    public dispose(): void {
        super.dispose();
        this.presenter?.onDisposed();
    }
}
